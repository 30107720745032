@font-face {
  font-family: "HankenGroteskRegular";
  src: url("./assets/fonts/HankenGrotesk/HankenGrotesk-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "HankenGroteskBold";
  src: url("./assets/fonts/HankenGrotesk/HankenGrotesk-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

body {
  margin: 0;
  font-family: "HankenGroteskRegular", "sans-serif";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F8F8F8;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

html, body, #root, #root>div {
  height: 100% !important;
}
