.tooltip {
  border-color: #DEDEDE;
}

/* default values */

g.node>circle {
  fill: white;
  stroke: grey;
}

g.link>line {
  stroke: grey;
}

marker.marker>path {
  fill: grey;
}

/* specific values */

/* Empresas */

g.node[data-types~="Contribuyente"]>circle {
  fill: white;
  stroke: #5C7B8E;
}

g.link[data-source-types~="Contribuyente"]>line {
  stroke: #5C7B8E;
}

marker.marker[data-source-types~="Contribuyente"]>path {
  fill: #5C7B8E;
}

.tooltip[data-types~="Contribuyente"]>.tooltipHeader {
  background-color: #5C7B8E;
}

g.node[data-types~="Grupo"]>circle {
  fill: white;
  stroke: #DE4242;
}

g.link[data-source-types~="Grupo"]>line {
  stroke: #DE4242;
}

marker.marker[data-source-types~="Grupo"]>path {
  fill: #DE4242;
}

.tooltip[data-types~="Grupo"]>.tooltipHeader {
  background-color: #DE4242;
}

g.node[data-types~="Actividad"]>circle {
  fill: white;
  stroke: #048CC2;
}

g.link[data-source-types~="Actividad"]>line {
  stroke: #048CC2;
}

marker.marker[data-source-types~="Actividad"]>path {
  fill: #048CC2;
}

.tooltip[data-types~="Actividad"]>.tooltipHeader {
  background-color: #048CC2;
}

/* Familias */

g.node[data-types~="Familia"]>circle {
  fill: white;
  stroke: #009688;
}

g.link[data-source-types~="Familia"]>line {
  stroke: #009688;
}

marker.marker[data-source-types~="Familia"]>path {
  fill: #009688;
}

.tooltip[data-types~="Familia"]>.tooltipHeader {
  background-color: #009688;
}

g.node[data-types~="Famprobable"]>circle {
  fill: white;
  stroke: #7653bd;
}

g.link[data-source-types~="Famprobable"]>line {
  stroke: #7653bd;
}

marker.marker[data-source-types~="Famprobable"]>path {
  fill: #7653bd;
}

.tooltip[data-types~="Famprobable"]>.tooltipHeader {
  background-color: #7653bd;
}

g.node[data-types~="Personas"]>circle {
  fill: white;
  stroke: #eda62f;
}

g.link[data-source-types~="Personas"]>line {
  stroke: #eda62f;
}

marker.marker[data-source-types~="Personas"]>path {
  fill: #eda62f;
}

.tooltip[data-types~="Personas"]>.tooltipHeader {
  background-color: #eda62f;
}

/* Causas */

g.node[data-types~="Causa"]>circle {
  fill: white;
  stroke: purple;
}

g.link[data-source-types~="Causa"]>line {
  stroke: purple;
}

marker.marker[data-source-types~="Causa"]>path {
  fill: purple;
}

.tooltip[data-types~="Causa"]>.tooltipHeader {
  background-color: purple;
}

/* factoring.unholster.com nodes */

g.node[data-types~="Persona"]>circle {
  fill: white;
  stroke: #eda62f;
}

g.link[data-source-types~="Persona"]>line {
  stroke: #eda62f;
}

marker.marker[data-source-types~="Persona"]>path {
  fill: #eda62f;
}

.tooltip[data-types~="Persona"]>.tooltipHeader {
  background-color: #eda62f;
}

g.node[data-types~="Empresa"]>circle {
  fill: white;
  stroke: #3892F7;
}

g.link[data-source-types~="Empresa"]>line {
  stroke: #3892F7;
}

marker.marker[data-source-types~="Empresa"]>path {
  fill: #3892F7;
}

.tooltip[data-types~="Empresa"]>.tooltipHeader {
  background-color: #3892F7;
}

g.node[data-types~="Holding"]>circle {
  fill: white;
  stroke: green;
}

g.link[data-source-types~="Holding"]>line {
  stroke: green;
}

marker.marker[data-source-types~="Holding"]>path {
  fill: green;
}

.tooltip[data-types~="Holding"]>.tooltipHeader {
  background-color: green;
}

g.node[data-types~="Auto"]>circle {
  fill: white;
  stroke: #E41B17;
}

g.link[data-source-types~="Auto"]>line {
  stroke: #E41B17;
}

marker.marker[data-source-types~="Auto"]>path {
  fill: #E41B17;
}

.tooltip[data-types~="Auto"]>.tooltipHeader {
  background-color: #E41B17;
}

g.node[data-types~="BienRaiz"]>circle {
  fill: white;
  stroke: #27AE60;
}

g.link[data-source-types~="BienRaiz"]>line {
  stroke: #27AE60;
}

marker.marker[data-source-types~="BienRaiz"]>path {
  fill: #27AE60;
}

.tooltip[data-types~="BienRaiz"]>.tooltipHeader {
  background-color: #27AE60;
}
.red-link>line {
  stroke: #6ea062 !important;
}

.red-link>path {
  fill: #6ea062 !important;
}

.green-link>line {
  stroke: lightcoral !important;
}

.green-link>path {
  fill: lightcoral !important;
}

g.node.filled>circle {
  fill: #E6745C;
}

g.node.filled>text {
  fill: white;
}

g.node.main-node-empresa>circle {
  fill: #3892F7;
}

g.node.main-node-empresa>text {
  fill: white;
}

g.node.main-node-persona>circle {
  fill: #eda62f;
}

g.node.main-node-persona>text {
  fill: white;
}